<template>
  <div class="feedback-range flow">
    <div
      class="feedback-range__header"
      v-if="!$basil.isNil(title)">
      <h3 class="feedback-range__title">{{ title }}</h3>
    </div>

    <div class="feedback-range__body">
      <div class="feedback-range__input">
        <input
          :disabled="readonly"
          type="range"
          min="0"
          :max="max"
          v-model="currentValue"
          @input="update"
        />
        <div class="feedback-range__total">
          {{ displayedValue }} / {{ max }}
        </div>
      </div>
      <div class="feedback-range__texts">
        <span>{{ $t('user-portal.survey_range_min') }}</span>
        <span>{{ $t('user-portal.survey_range_medium') }}</span>
        <span>{{ $t('user-portal.survey_range_max') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackStars',

  props: {
    data: {
      type: Object,
      required: true
    },

    value: {
      type: Number,
      default: 0,
    },

    errors: {
      type: Array,
      default: []
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentValue: 0
    }
  },

  computed: {
    displayedValue () {
      return this.currentValue || this.value
    },

    max() {
      return this.$basil.get(this.data, 'number', 10)
    },

    title() {
      return this.$basil.get(this.data, 'title')
    }
  },

  methods: {
    update() {
      this.$emit('input', parseInt(this.currentValue, 10))
    }
  },

  mounted() {
    this.currentValue = this.$basil.get(this, 'value', 0)
  }
}
</script>
