<template>
  <form
    class="survey-user flow"
    @submit="onSubmit">
    <div
      class="survey-user__header">
      <h3 class="survey-user__title">{{ $t('user-portal.survey_user_title') }}</h3>
      <p class="survey-user__description">{{ $t('user-portal.survey_user_description') }}</p>
    </div>
    <!-- Survey user form -->
    <div class="survey-user__body flow">
      <div class="group">
        <!-- Firstname -->
        <forms-input
          autocomplete
          :errors="getErrors('firstname')"
          name="given-name"
          :placeholder="$t('user-portal.user_firstname_placeholder')"
          required
          @input="onRemoveError('firstname')"
          v-model="user.firstname"
        >{{ $t('user-portal.user_firstname') }}</forms-input>

        <!-- Lastname -->
        <forms-input
          autocomplete
          :errors="getErrors('lastname')"
          name="family-name"
          ref="lastname"
          required
          :placeholder="$t('user-portal.user_lastname_placeholder')"
          @input="onRemoveError('lastname')"
          v-model="user.lastname"
        >{{ $t('user-portal.user_lastname') }}</forms-input>
      </div>

      <div class="group">
        <!-- Email -->
        <forms-input
          autocomplete
          :errors="getErrors('email')"
          name="email"
          :placeholder="$t('user-portal.user_email_placeholder')"
          ref="email"
          :required="true"
          type="email"
          @input="onRemoveError('email')"
          v-model="user.email"
        >{{ $t('user-portal.user_email') }}</forms-input>

        <!-- Phone -->
        <forms-input
          v-if="false"
          autocomplete
          :errors="getErrors('phone')"
          :hint="$t('user-portal.user_phone_number_hint')"
          name="phone"
          :placeholder="$t('user-portal.user_phone_number_placeholder')"
          ref="phone"
          :required="false"
          @input="onRemoveError('phone')"
          v-model="user.phone"
        >{{ $t('user-portal.user_phone_number') }}</forms-input>
      </div>

      <forms-checkbox 
        :size="$pepper.Size.L"
        v-model="user.opt_in">
        <div class="survey-user__terms-content">
          <span
            class="survey-user__terms"
            v-html="$t('user-portal.user_newsletter')"
          ></span>
        </div>
      </forms-checkbox>

      <div class="survey-user__actions">
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          @click="submit"
        >{{ $t('user-portal.survey_user_submit') }}</actions-button>
      </div>
    </div>
  </form>
</template>

<script>
import MixinErrors from '@/helpers/errors'

export default {
  name: 'SurveyUser',

  mixins: [
    MixinErrors
  ],

  props: {
    errors: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      user: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        opt_in: false
      }
    }
  },

  methods: {
    onSubmit(e) {
      e.preventDefault()
      this.submit()
    },

    submit() {
      this.$emit('submit', this.user)
    }
  }
}
</script>